import * as Sentry from '@sentry/nuxt'

Sentry.init({
  dsn: 'https://13897f1a189c1744331a4e518cc7ed30@o4506615133372416.ingest.us.sentry.io/4506660636327936',
  // enabled: process.env.NODE_ENV !== 'development',
  enabled: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/cabinet.laoshi\.io\/v1.1/],
  integrations: [
    Sentry.browserTracingIntegration({
      tracingOrigins: ['localhost', 'https://cabinet.laoshi.io/v1.1']
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

})
